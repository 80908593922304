import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { XIcon, UploadIcon, ReplyIcon } from "@heroicons/react/solid";
import DragAndDrop from "../DragAndDrop";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PictureEditor({
  imageFile,
  onDrop,
  clearImage,
  auth,
  currentImage,
}: any) {
  const [rotateImage, setRotateImage]: [rotateImage: any, setRotateImage: any] =
    useState(0);

  const [scaleImage, setScaleImage]: [scaleImage: any, setScaleImage: any] =
    useState(1);

  const [uploadDisabled, setUploadDisabled]: [
    uploadDisabled: any,
    setUploadDisabled: any
  ] = useState(false);

  const [editor, setEditor]: [editor: any, setEditor: any] = useState(null);
  const [currentImageUrl, setCurrentImageUrl]: [currentImageUrl: any, setCurrentImageUrl: any] = useState(currentImage);

  function rotateLeft() {
    setRotateImage(rotateImage - 90);
  }

  function zoomImage(value: any) {
    setScaleImage(parseFloat(value.target.value));
  }

  const setEditorRef = (editorRef: any) => {
    if (editorRef) setEditor(editorRef);
  };

  async function updateOktaProfilePicture(imageUrl: string) {
    const body: any = {
      profile: {
        avatarUrl: imageUrl,
      },
    };

    await fetch(`${process.env.REACT_APP_OKTA_ISSUER}/api/v1/users/me`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.accessToken.accessToken}`,
      },
      body: JSON.stringify(body),
    });
  }

  async function uploadImageToServer() {
    // Get the presigned URL
    const response = await fetch(
      `/api/profile/picture?id=${auth.accessToken.claims.uid}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      }
    );
    return response.json();
  }

  function getUrlFromBucket(fileName: string) {
    return `${process.env.REACT_APP_BUCKET_URL}/${fileName}`;
  }

  async function uploadImage() {
    try {
      setUploadDisabled(true);
      const image = editor.getImageScaledToCanvas().toDataURL();
      const serverResponse = await uploadImageToServer();

      let binary = atob(image.split(",")[1]);
      let array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });

      await fetch(serverResponse.uploadURL, {
        method: "PUT",
        body: blobData,
      });

      const imageUrl = getUrlFromBucket(serverResponse.Key);

      await updateOktaProfilePicture(imageUrl);
      if (currentImageUrl) {
        await deletePreviousImage(currentImageUrl);
      }
      setCurrentImageUrl(imageUrl);
      toast.dark("Image Upload Completed");
      setUploadDisabled(false);
    } catch (error) {
      console.log(error);
      toast.error("Error uploading image");
      setUploadDisabled(false);
    }
  }

  async function deletePreviousImage(previousImageUrl: string) {
    try {
      const body: any = {
        previousImageUrl: previousImageUrl,
      };

      await fetch("/api/profile/picture", {
        method: "DELETE",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      });
    } catch (error) {
      console.log(error);
      toast.error("Error removing previous image");
    }
  }

  return (
    <div className="flex flex-col flex-wrap flex-auto gap-8 lg:flex-nowrap md:flex-row">
      <div className="relative">
        <AvatarEditor
          ref={setEditorRef}
          image={imageFile || "https://images.auctane.com/user_headshots/temp.jpg"}
          width={500}
          height={500}
          border={50}
          color={[0, 0, 0, 0.75]} // RGBA
          scale={scaleImage}
          rotate={rotateImage}
          className="w-full border border-gray-200 md:w-auto"
          style={{
            width: "none",
            height: "auto",
            maxWidth: "600px",
          }}
        />
        <button
          onClick={clearImage}
          className="absolute z-10 w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800"
        >
          <XIcon className="text-white" />
        </button>
      </div>
      <div className="flex flex-col flex-auto w-full gap-8">
        <div className="hidden md:block">
          <h3 className="text-lg font-bold">Instructions:</h3>
          <p>
            Edit the current image or upload a new image. Your image will be
            cropped to a 500px x 500px image. Use the rotate, zoom, and image
            drag to center your portrait.
          </p>
        </div>
        <div className="hidden w-full md:block">
          <DragAndDrop onDrop={onDrop} accept="image/jpeg,image/png"/>
        </div>
        <h3 className="text-lg font-bold">Edit Image:</h3>
        <div className="flex flex-col flex-wrap gap-8 md:flex-row">
          <div>
            <button
              onClick={rotateLeft}
              className="flex flex-row items-center px-6 py-3 space-x-2 text-xl font-bold text-gray-900 bg-gray-200 border rounded-full hover:bg-opacity-80"
            >
              <span>Rotate</span>
              <span>
                <ReplyIcon className="inline w-6 text-gray-900" />
              </span>
            </button>
          </div>
          <label className="flex flex-row items-center space-x-4">
            <div className="text-xl font-semibold">Zoom:</div>
            <input
              className="h-3 bg-gray-200 rounded-lg appearance-none cursor-move w-128"
              type="range"
              defaultValue="1"
              min="1"
              max="2"
              name="scale"
              step="0.01"
              onChange={zoomImage}
            />
          </label>
        </div>
        <div className="mt-2">
          <button
            onClick={uploadImage}
            className={`flex flex-row px-8 py-4 space-x-2 text-xl font-bold text-white rounded-full ${
              uploadDisabled
                ? "bg-gray-300 cursor-wait"
                : "bg-blue-900 hover:bg-opacity-80"
            }`}
            disabled={uploadDisabled}
          >
            <span>Save Image</span>
            <span>
              <UploadIcon className="inline w-6 text-white" />
            </span>
          </button>
        </div>
      </div>
      <div
        id="toast"
        className="fixed flex flex-col space-y-4 bottom-8 right-8"
      >
        <ToastContainer
          autoClose={5000}
          position="bottom-right"
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default PictureEditor;
